import React from 'react';
import styled from 'styled-components';

const MyButton = styled.button<{ size?: string }>`
  align-items: center;
  background: none;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  filter: invert(1);
  height: ${(props) =>
    props.size === 'large' ? '32px' : props.size === 'small' ? '16px' : '24px'};
  justify-content: center;
  line-height: ${(props) =>
    props.size === 'large' ? '32px' : props.size === 'small' ? '16px' : '24px'};
  outline: 0;
  overflow: hidden;
  pointer-events: auto;
  text-align: center;
  width: ${(props) =>
    props.size === 'large' ? '32px' : props.size === 'small' ? '16px' : '24px'};

  :hover {
    filter: invert(0.5);
  }
`;

type ButtonProps = {
  icon: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium' | 'large';
  tooltipId?: string;
  tooltipContent?: string;
};

const IconButton = ({
  icon,
  onClick,
  size = 'medium',
  tooltipId,
  tooltipContent,
}: ButtonProps) => {
  const iconSize =
    size === 'large' ? '24px' : size === 'small' ? '12px' : '16px';
  return (
    <MyButton
      onClick={onClick}
      size={size}
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipContent}
    >
      <img
        alt="button icon"
        src={`/icons/${icon}.svg`}
        height={iconSize}
        width={iconSize}
      />
    </MyButton>
  );
};

export default IconButton;
